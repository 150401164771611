import { Button, Hidden, Typography } from "@material-ui/core";
import { ArrowRightAlt as ArrowIcon } from "@material-ui/icons";
import { navigate } from "gatsby";
import React from "react";
import lineIcons from "../assets/images/line-icons.png";
import notFoundToyImage from "../assets/images/not-found-page-toy-image.png";
import DefaultLayout from "../layouts/default-layout";
import useStyles from "../styles/404/styles";

function NotFoundPage() {
  const classes = useStyles();
  return (
    <DefaultLayout>
      <div className={classes.notFoundPage}>
        <Hidden smUp>
          <img src={lineIcons} alt="" className={classes.lineIcons} />
        </Hidden>
        <div className={classes.notFoundTextArea}>
          <div>
            <img
              src={notFoundToyImage}
              alt="Página não encontrada"
              className={classes.notFoundToyImage}
            />
          </div>
          <div className={classes.notFoundPageTextContainer}>
            <Typography variant="h2" className={classes.notFoundPageTitleText}>
              página não encontrada
            </Typography>
            <Typography
              variant="body1"
              className={classes.notFoundPageSubtitleText}
            >
              Procuramos por toda parte, mas não conseguimos encontrar a página
              que você está procurando. Busque novamente ou volte para a página
              inicial da Smart Baby Box.
            </Typography>
            <div className={classes.initialPageButtonContainer}>
              <Button
                variant="contained"
                endIcon={<ArrowIcon />}
                className={classes.initialPageButton}
                onClick={() => navigate("/")}
              >
                Página inicial
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default NotFoundPage;
